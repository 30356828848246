'use client';

import {
    ChangeEvent,
    FC,
    ReactElement,
    useRef,
} from 'react';

import classNames from 'classnames';

import { IconButton } from '@/compositions/@buttons/IconButton/IconButton';
import { Input, InputProps } from '@/compositions/@inputs/Input/Input';
import { InputLabelProps } from '@/compositions/@inputs/InputLabel/InputLabel';
import { InputLabelWrapper } from '@/compositions/@inputs/InputLabelWrapper/InputLabelWrapper';
import useTrans from '@/hooks/useTrans';

import './NumberInput.scss';

interface NumberInputProps extends InputLabelProps, Omit<InputProps, 'onChange'> {
    onChange: (value: number) => void;
}

export const NumberInput: FC<NumberInputProps> = ({
    label,
    hideLabel,
    required,
    tabIndex,
    disabled,
    tooltip,
    error,
    onChange,
    className = '',
    ...inputProps
}): ReactElement => {
    const trans = useTrans();

    const inputRef = useRef<HTMLInputElement>(null);

    const handleMinusClick = (): void => {
        if (inputRef.current) {
            inputRef.current.stepDown();
            onChange(Number(inputRef.current.value));
        }
    };

    const handlePlusClick = (): void => {
        if (inputRef.current) {
            inputRef.current.stepUp();
            onChange(Number(inputRef.current.value));
        }
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>): void => onChange(Number(event.currentTarget.value));
    const handleFocus = (event: ChangeEvent<HTMLInputElement>): void => event.currentTarget.select();

    const inputFieldClassNames = classNames('number-input__field', {
        'number-input__field--has-error': error,
    });

    return (
        <label aria-label={label} className={`number-input ${className}`}>
            <InputLabelWrapper
                hideLabel={hideLabel}
                label={label}
                tooltip={tooltip}
                required={required}
                error={error}
            >
                <div className="number-input__field-wrapper">
                    <Input
                        {...inputProps}
                        ref={inputRef}
                        hasControls
                        type="number"
                        required={required}
                        tabIndex={tabIndex}
                        disabled={disabled}
                        error={error}
                        onChange={handleChange}
                        onFocus={handleFocus}
                        fieldClassName={inputFieldClassNames}
                    />

                    <IconButton
                        icon="minus"
                        hideLabel
                        text={trans('compositions.numberInput.stepDown')}
                        tabIndex={tabIndex}
                        disabled={disabled}
                        onClick={handleMinusClick}
                        className="number-input__control-button number-input__control-button--minus"
                    />

                    <IconButton
                        icon="plus"
                        hideLabel
                        text={trans('compositions.numberInput.stepUp')}
                        tabIndex={tabIndex}
                        disabled={disabled}
                        onClick={handlePlusClick}
                        className="number-input__control-button number-input__control-button--plus"
                    />
                </div>
            </InputLabelWrapper>
        </label>
    );
};
