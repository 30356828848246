import { FC, ReactElement } from 'react';

import { ButtonProps } from '@/compositions/@buttons/Button/Button';
import { IconButton } from '@/compositions/@buttons/IconButton/IconButton';

import './FilterButton.scss';

export interface FilterButtonProps extends ButtonProps {
    className?: string;
}

export const FilterButton: FC<FilterButtonProps> = ({
    className = '',
    ...buttonProps
}): ReactElement => (
    <IconButton
        {...buttonProps}
        icon="plus"
        className={`filter-button ${className}`}
        iconClassName="filter-button__icon"
    />
);
