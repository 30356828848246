import { FC, ReactElement } from 'react';

import { Icon, Wrapper } from '@/components';
import { UspSectionInterface } from '@/entities/@blocks/UspSection/UspSection';

import './UspSection.scss';

interface UspSectionProps extends UspSectionInterface {
    className?: string;
}

export const UspSection: FC<UspSectionProps> = ({
    items,
    className = '',
}): ReactElement => (
    <section className={`usp-section ${className}`}>
        <Wrapper className="usp-section__wrapper">
            <ul className="usp-section__list">
                {items.map(item => (
                    <li key={item.id} className="usp-section__item">
                        <Icon name={item.icon} className="usp-section__item-icon" />

                        <p className="usp-section__item-text">
                            {item.text}
                        </p>
                    </li>
                ))}
            </ul>
        </Wrapper>
    </section>
);
