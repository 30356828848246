'use client';

import React, {
    FC,
    PropsWithChildren,
    ReactElement,
    TouchEvent,
    useState,
} from 'react';

interface SwipeDetectorProps {
    threshold?: number;
    onSwipeUp?: () => void;
    onSwipeDown?: () => void;
    onSwipeLeft?: () => void;
    onSwipeRight?: () => void;
    className?: string;
}

export const SwipeDetector: FC<PropsWithChildren<SwipeDetectorProps>> = ({
    threshold = 50,
    onSwipeUp,
    onSwipeDown,
    onSwipeLeft,
    onSwipeRight,
    className = '',
    children,
}): ReactElement => {
    const [xDown, setXDown] = useState<number | null>(null);
    const [yDown, setYDown] = useState<number | null>(null);

    const handleTouchStart = (event: TouchEvent<HTMLDivElement>): void => {
        setXDown(event.touches[0].clientX);
        setYDown(event.touches[0].clientY);
    };

    const handleTouchMove = (event: TouchEvent<HTMLDivElement>): void => {
        if (!xDown || !yDown) {
            return;
        }

        const xUp = event.touches[0].clientX;
        const yUp = event.touches[0].clientY;

        const xDiff = xDown - xUp;
        const yDiff = yDown - yUp;

        if (Math.abs(xDiff) + Math.abs(yDiff) < threshold) {
            return;
        }

        const horizontalSwipe = Math.abs(xDiff) > Math.abs(yDiff);

        if (horizontalSwipe) {
            if (xDiff > 0 && onSwipeLeft) {
                onSwipeLeft();
            }

            if (xDiff < 0 && onSwipeRight) {
                onSwipeRight();
            }
        } else {
            if (yDiff > 0 && onSwipeUp) {
                onSwipeUp();
            }

            if (yDiff < 0 && onSwipeDown) {
                onSwipeDown();
            }
        }

        setXDown(null);
        setYDown(null);
    };

    return (
        <div
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            className={className}
        >
            {children}
        </div>
    );
};
