import { FC, ReactElement } from 'react';

import { Icon } from '@/components';
import { Button, ButtonProps } from '@/compositions/@buttons/Button/Button';
import { WayfindingWrapper } from '@/compositions/WayfindingWrapper/WayfindingWrapper';
import { Direction } from '@/entities/Navigation/Navigation';
import { WayfindingType } from '@/entities/Wayfinding/Wayfinding';

import './WayfindingDirectionButton.scss';

interface WayfindingDirectionButtonProps extends ButtonProps {
    id: string;
    direction: Direction;
    className?: string;
}

export const WayfindingDirectionButton: FC<WayfindingDirectionButtonProps> = ({
    id,
    text,
    direction,
    className = '',
    ...buttonProps
}): ReactElement => {
    const type = direction === Direction.prev
        ? WayfindingType.left
        : WayfindingType.right;

    const icon = direction === Direction.prev
        ? 'wayfinding-left'
        : 'wayfinding-right';

    return (
        <WayfindingWrapper
            id={`${id}-${direction}`}
            type={type}
            className={`wayfinding-direction-button ${className}`}
            iconPathClassName="wayfinding-direction-button__icon-path"
        >
            <Button
                {...buttonProps}
                text={text}
                className="wayfinding-direction-button__button"
                labelClassName="wayfinding-direction-button__button-label"
            >
                <Icon
                    name={icon}
                    className="wayfinding-direction-button__icon"
                    pathClassName="wayfinding-direction-button__icon-path"
                />
            </Button>
        </WayfindingWrapper>
    );
};
