import { generateUuid } from '@/helpers/string';
import { AtLeast } from '@/types';

export enum ToastPosition {
    blockStartInlineStart = 'block-start-inline-start',
    blockStartInlineEnd = 'block-start-inline-end',
    blockEndInlineStart = 'block-end-inline-start',
    blockEndInlineEnd = 'block-end-inline-end',
}

export enum ToastState {
    positive = 'positive',
    warning = 'warning',
    negative = 'negative',
}

export interface Toast {
    id: string;
    revealDuration?: number;
    transitionDuration?: number;
    position?: ToastPosition;
    state?: ToastState;
    title: string;
    description?: string;
}

export const defaultToastRevealDuration = 5000;

export const defaultToastProperties = (): AtLeast<Toast, 'id'> => ({
    id: generateUuid(),
    revealDuration: defaultToastRevealDuration,
    position: ToastPosition.blockEndInlineEnd,
});
