import { FC, ReactElement } from 'react';

import Link from 'next/link';

import { Price } from '@/components/Price/Price';
import { Picture } from '@/compositions/Picture/Picture';
import { Product } from '@/entities/Product/Product';

import './ProductCard.scss';

export interface ProductCardProps {
    product: Product;
    className?: string;
    imageClassname?: string;
    pictureClassname?: string;
}

export const ProductCard: FC<ProductCardProps> = ({
    product,
    className = '',
    imageClassname = '',
    pictureClassname = '',
}): ReactElement => (
    <div className={`product-card ${className}`}>
        <div className="product-card__image-wrapper">
            {product.images.length > 0 && (
                <Picture
                    {...product.images[0]}
                    className={`product-card__image ${pictureClassname}`}
                    imageClassName={imageClassname}
                />
            )}
        </div>

        <Link href={`/brand/${product.brand.slug}`} className="product-card__brand-link">
            <h3 className="product-card__brand">
                {product.brand.name}
            </h3>
        </Link>

        <Link href={`/product/${product.id}`} className="product-card__product-link">
            <h2 className="product-card__name">
                {product.name}
            </h2>
        </Link>

        <Price amount={product.price} className="product-card__price" />
    </div>
);
