import { FC, ReactElement } from 'react';

import classNames from 'classnames';

import { Checkbox } from '@/compositions/@inputs/Checkbox/Checkbox';
import { InputProps } from '@/compositions/@inputs/Input/Input';
import { InputLabelProps } from '@/compositions/@inputs/InputLabel/InputLabel';
import { InputLabelWrapper } from '@/compositions/@inputs/InputLabelWrapper/InputLabelWrapper';
import { FormOption } from '@/entities/Form/Form';

import './CheckboxList.scss';

interface CheckboxListProps extends InputLabelProps, Omit<InputProps, 'onChange'> {
    isSmall?: boolean;
    name: string;
    value: string[];
    options: FormOption[];
    onChange: (values: string[]) => void;
    className?: string;
}

export const CheckboxList: FC<CheckboxListProps> = ({
    isSmall,
    id,
    label,
    hideLabel,
    value = [],
    options,
    tooltip,
    error,
    onChange,
    className = '',
    ...inputProps
}): ReactElement => {
    const handleCheckboxChange = (option: FormOption, isChecked: boolean): void => {
        const newCheckedOptions = isChecked
            ? [...value, option.value]
            : [...value].filter(selectedOption => selectedOption !== option.value);

        onChange(newCheckedOptions);
    };

    const checkboxListClassNames = classNames('checkbox-list', {
        'checkbox-list--is-small': isSmall,
    }, className);

    const checkboxListOptionWrapperClassNames = classNames('checkbox-list__option-wrapper', {
        'checkbox-list__option-wrapper--has-error': error,
    });

    return (
        <div id={id} className={checkboxListClassNames}>
            <InputLabelWrapper
                hideLabel={hideLabel}
                label={label}
                tooltip={tooltip}
                error={error}
                labelClassName="checkbox-list__label"
            >
                <div className={checkboxListOptionWrapperClassNames}>
                    {options.map(option => {
                        const handleSelect = (isChecked: boolean): void => handleCheckboxChange(option, isChecked);

                        return (
                            <Checkbox
                                {...inputProps}
                                key={option.value}
                                isSmall={isSmall}
                                label={option.label}
                                value={option.value}
                                checked={value.includes(option.value)}
                                onChange={handleSelect}
                                className="checkbox-list__item"
                            />
                        );
                    })}
                </div>
            </InputLabelWrapper>
        </div>
    );
};
