/* eslint-disable max-len */
import {
    forwardRef,
    ForwardRefExoticComponent,
    ReactElement,
    Ref,
} from 'react';

import { SvgIconProps } from '@/entities/Svg/Svg';

export const IconCartBag: ForwardRefExoticComponent<SvgIconProps> = forwardRef(({
    className = '',
    accentClassName = '',
}, ref: Ref<SVGSVGElement>): ReactElement => (
    <svg ref={ref} viewBox="0 0 100 100" className={`icon ${className}`}>
        <path className={accentClassName} d="M32.7,43.9h34.1v6.7h-25.8v5.4c.3-.3.6-.6.9-.8.3-.2.7-.5,1.2-.6.4-.2,1-.3,1.6-.4.6,0,1.4-.1,2.2-.1h10.9c1.7,0,3.2.2,4.5.5,1.3.3,2.4.8,3.3,1.5.9.7,1.6,1.6,2.1,2.8.5,1.2.7,2.6.7,4.3v2c0,2.4-.3,4.3-.9,5.6-.6,1.4-1.6,2.4-2.8,3.1-1.2.7-2.8,1.1-4.7,1.3-1.9.2-4.1.3-6.6.3h-7.5c-2.4,0-4.4,0-6.2-.3-1.8-.2-3.2-.5-4.4-1.1-1.2-.6-2-1.4-2.6-2.5-.6-1.1-.9-2.6-.9-4.4v-1.8h8.8c0,.7,0,1.2.2,1.6.1.4.4.8.7,1,.4.3.9.4,1.6.5.7,0,1.6.2,2.7.2h9c1.1,0,2,0,2.6-.2.6-.2,1.1-.4,1.5-.8.3-.3.5-.8.6-1.3.1-.5.2-1.2.2-1.9s0-1.6-.3-2.1c-.2-.5-.5-.9-.9-1.2-.4-.3-1-.4-1.7-.5-.7,0-1.6-.1-2.7-.1h-10.1c-.7,0-1.4.2-1.9.5-.5.3-.8.9-.9,1.6h-8.3v-18.8Z" />
        <rect className="icon__stroke" x="16.6" y="27.4" width="67" height="61.8" />
        <path className="icon__stroke" d="M61.7,27.4c.5-1.3.7-2.7.7-4.2,0-6.9-5.6-12.4-12.4-12.4s-12.4,5.6-12.4,12.4.3,2.9.7,4.2" />
    </svg>
));
