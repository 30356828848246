'use client';

import { ChangeEvent, FC, ReactElement } from 'react';

import { useToggle } from 'react-use';

import { IconButton } from '@/compositions/@buttons/IconButton/IconButton';
import { Input, InputProps } from '@/compositions/@inputs/Input/Input';
import { InputLabelProps } from '@/compositions/@inputs/InputLabel/InputLabel';
import { InputLabelWrapper } from '@/compositions/@inputs/InputLabelWrapper/InputLabelWrapper';
import useTrans from '@/hooks/useTrans';

import './PasswordInput.scss';

interface PasswordInputProps extends InputLabelProps, Omit<InputProps, 'onChange'> {
    onChange: (value: string) => void;
}

export const PasswordInput: FC<PasswordInputProps> = ({
    label,
    hideLabel,
    required,
    tooltip,
    disabled,
    error,
    onChange,
    className = '',
    ...inputProps
}): ReactElement => {
    const trans = useTrans();
    const [passwordIsVisible, togglePasswordIsVisible] = useToggle(false);

    const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
        onChange(event.currentTarget.value);
    };

    return (
        <label aria-label={label} className={`password-input ${className}`}>
            <InputLabelWrapper
                hideLabel={hideLabel}
                label={label}
                required={required}
                tooltip={tooltip}
                error={error}
            >
                <div className="password-input__field-wrapper">
                    <Input
                        {...inputProps}
                        hasControls
                        type={passwordIsVisible ? 'text' : 'password'}
                        required={required}
                        disabled={disabled}
                        error={error}
                        onChange={handleChange}
                        fieldClassName="password-input__field"
                    />

                    <IconButton
                        icon={passwordIsVisible ? 'visibility-on' : 'visibility-off'}
                        text={trans(`compositions.passwordInput.${passwordIsVisible ? 'hide' : 'show'}`)}
                        hideLabel
                        disabled={disabled}
                        onClick={togglePasswordIsVisible}
                        className="password-input__visibility-button"
                        iconClassName="password-input__visibility-icon"
                    />
                </div>
            </InputLabelWrapper>
        </label>
    );
};
