/* eslint-disable max-len */
import {
    forwardRef,
    ForwardRefExoticComponent,
    ReactElement,
    Ref,
} from 'react';

import { SvgIconProps } from '@/entities/Svg/Svg';

export const IconOrders: ForwardRefExoticComponent<SvgIconProps> = forwardRef(({
    className = '',
}, ref: Ref<SVGSVGElement>): ReactElement => (
    <svg ref={ref} viewBox="0 0 100 100" className={`icon ${className}`}>
        <path className="icon__stroke" d="M4.2,41.7h8.3M95.8,41.7h-8.3M87.5,41.7l-12.5,47.9H25l-12.5-47.9M87.5,41.7h-12.5M12.5,41.7h12.5M25,41.7l16.7-31.2M25,41.7h50M75,41.7l-16.7-31.2M50,50v15.6M50,65.6v15.6M50,65.6h14.6M50,65.6h-14.6" />
    </svg>
));
