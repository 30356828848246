import {
    ButtonHTMLAttributes,
    forwardRef,
    ForwardRefExoticComponent,
    ReactElement,
    Ref,
    RefAttributes,
} from 'react';

import classNames from 'classnames';

import './Button.scss';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement>, RefAttributes<HTMLButtonElement> {
    hasAnimation?: boolean;
    isSmall?: boolean;
    text: string;
    hideLabel?: boolean;
    labelClassName?: string;
}

export const Button: ForwardRefExoticComponent<ButtonProps> = forwardRef(({
    hasAnimation,
    isSmall,
    type = 'button',
    text,
    hideLabel,
    className = '',
    labelClassName = '',
    children,
    ...buttonProps
}, ref: Ref<HTMLButtonElement>): ReactElement => {
    const buttonClassNames = classNames('button', {
        'button--is-small': isSmall,
        'button--has-animation': hasAnimation,
    }, className);

    return (
        <button
            {...buttonProps}
            ref={ref}
            type={type} // eslint-disable-line react/button-has-type
            title={hideLabel || children ? text : undefined}
            className={buttonClassNames}
        >
            {!hideLabel && (
                <span data-text={text} className={`button__label ${labelClassName}`}>
                    {hasAnimation ? text : children || text}
                </span>
            )}
        </button>
    );
});
