import { FC, ReactElement } from 'react';

import {
    checkEndForActivePage,
    checkStartForActivePage,
    generateCenterList,
    generateCenterNumber,
    generateEndList,
    generateStartList,
} from '@/compositions/@inputs/Pagination/helpers';
import { PaginationLink } from '@/compositions/@inputs/Pagination/subcomponents/PaginationLink/PaginationLink';
import { generateIdArray } from '@/helpers/array';

import './LongPagination.scss';

interface LongPaginationProps {
    amountOfPages: number;
    currentPage: number;
    marginInitial: number;
    marginCenter: number;
    onChange: (page: number) => void;
    className?: string;
}

export const LongPagination: FC<LongPaginationProps> = ({
    amountOfPages,
    currentPage,
    marginInitial,
    marginCenter,
    onChange,
    className = '',
}): ReactElement => {
    const pageNumbers = generateIdArray(amountOfPages).map(page => page + 1);

    const startList = generateStartList(pageNumbers, marginInitial);
    const centerList = generateCenterList(pageNumbers, marginCenter, currentPage);
    const endList = generateEndList(pageNumbers, marginInitial);
    const centerPage = Math.round((endList[0] + startList[startList.length - 1]) / 2);

    const isAtStart = checkStartForActivePage(startList, currentPage);
    const isAtEnd = checkEndForActivePage(endList, currentPage);
    const isAtTip = isAtStart || isAtEnd;

    const paginationStart = isAtTip ? startList : [1];
    const paginationCenter = isAtTip ? [centerPage] : centerList;
    const paginationEnd = isAtTip ? endList : [amountOfPages];

    const midpointStart = generateCenterNumber(1, centerList[0], marginCenter);
    const midpointEnd = generateCenterNumber(centerList[centerList.length - 1], amountOfPages, marginCenter);

    return (
        <ul className={`long-pagination ${className}`}>
            {paginationStart.map(page => (
                <li key={page} className="long-pagination__list-item">
                    <PaginationLink
                        isActive={currentPage === page}
                        page={page}
                        onClick={onChange}
                        className="long-pagination__link"
                    />
                </li>
            ))}

            <li className="long-pagination__list-item">
                <span className="long-pagination__ellipsis">
                    …
                </span>
            </li>

            {!isAtTip && midpointStart && (
                <>
                    <li className="long-pagination__list-item">
                        <PaginationLink
                            isActive={currentPage === midpointStart}
                            page={midpointStart}
                            onClick={onChange}
                            className="long-pagination__link"
                        />
                    </li>

                    <li className="long-pagination__list-item">
                        <span className="long-pagination__ellipsis">
                            …
                        </span>
                    </li>
                </>
            )}

            {paginationCenter.map(page => (
                <li key={page} className="long-pagination__list-item">
                    <PaginationLink
                        isActive={currentPage === page}
                        page={page}
                        onClick={onChange}
                        className="long-pagination__link"
                    />
                </li>
            ))}

            {!isAtTip && midpointEnd && (
                <>
                    <li className="long-pagination__list-item">
                        <span className="long-pagination__ellipsis">
                            …
                        </span>
                    </li>

                    <li className="long-pagination__list-item">
                        <PaginationLink
                            isActive={currentPage === midpointEnd}
                            page={midpointEnd}
                            onClick={onChange}
                            className="long-pagination__link"
                        />
                    </li>
                </>
            )}

            <li className="long-pagination__list-item">
                <span className="long-pagination__ellipsis">
                    …
                </span>
            </li>

            {paginationEnd.map(page => (
                <li key={page} className="long-pagination__list-item">
                    <PaginationLink
                        isActive={currentPage === page}
                        page={page}
                        onClick={onChange}
                        className="long-pagination__link"
                    />
                </li>
            ))}
        </ul>
    );
};
