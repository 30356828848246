import { ChangeEvent, FC, ReactElement } from 'react';

import classNames from 'classnames';

import { InputProps } from '@/compositions/@inputs/Input/Input';
import { InputLabelProps } from '@/compositions/@inputs/InputLabel/InputLabel';
import { InputLabelWrapper } from '@/compositions/@inputs/InputLabelWrapper/InputLabelWrapper';
import { Radio } from '@/compositions/@inputs/Radio/Radio';
import { FormOption } from '@/entities/Form/Form';

import './RadioList.scss';

interface RadioListProps extends InputLabelProps, Omit<InputProps, 'onChange'> {
    id?: string;
    name: string;
    value: string;
    options: FormOption[];
    onChange: (value: string) => void;
    className?: string;
}

export const RadioList: FC<RadioListProps> = ({
    id,
    label,
    hideLabel,
    required,
    value,
    options,
    tooltip,
    error,
    onChange,
    className = '',
    ...inputProps
}): ReactElement => {
    const handleOnRadioChange = (event: ChangeEvent<HTMLInputElement>) => {
        onChange(event.currentTarget.value);
    };

    const radioListOptionWrapperClassNames = classNames('radio-list__option-wrapper', {
        'radio-list__option-wrapper--has-error': error,
    });

    return (
        <div id={id} className={`radio-list ${className}`}>
            <InputLabelWrapper
                hideLabel={hideLabel}
                label={label}
                required={required}
                tooltip={tooltip}
                error={error}
                labelClassName="radio-list__label"
            >
                <div className={radioListOptionWrapperClassNames}>
                    {options.map(option => (
                        <Radio
                            {...inputProps}
                            key={option.value}
                            required={required}
                            selected={value}
                            option={option}
                            onChange={handleOnRadioChange}
                            className="radio-list__radio"
                        />
                    ))}
                </div>
            </InputLabelWrapper>
        </div>
    );
};
