export const generateStartList = (pages: number[], visibleMargin: number): number[] => [...pages]
    .splice(0, visibleMargin);

export const generateCenterList = (pages: number[], visibleMargin: number, activePage: number): number[] => {
    const trimmedPages = [...pages].slice(1, -1);
    const length = visibleMargin + 1 + visibleMargin;

    const activePageIndex = trimmedPages.indexOf(activePage);
    const centerListStart = activePageIndex - visibleMargin;
    const cappedCenterListStart = Math.max(0, centerListStart);

    return [...trimmedPages].splice(cappedCenterListStart, length);
};

export const generateEndList = (pages: number[], visibleMargin: number): number[] => [...pages].splice(-visibleMargin);

export const checkStartForActivePage = (startPages: number[], activePage: number): boolean => {
    const isFound = startPages.includes(activePage);
    const isNotLast = activePage !== [...startPages].pop();

    return isFound && isNotLast;
};

export const checkEndForActivePage = (endPages: number[], activePage: number): boolean => {
    const isFound = endPages.includes(activePage);
    const isNotLast = activePage !== [...endPages].reverse().pop();

    return isFound && isNotLast;
};

export const generateCenterNumber = (startPage: number, endPage: number, marginCenter: number): number | undefined => {
    const range = startPage > endPage
        ? startPage - endPage
        : endPage - startPage;

    if (range <= (marginCenter + 1)) {
        return undefined;
    }

    return startPage + Math.round(range / 2);
};
