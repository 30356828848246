/* eslint-disable max-len */
import {
    forwardRef,
    ForwardRefExoticComponent,
    ReactElement,
    Ref,
} from 'react';

import { SvgIconProps } from '@/entities/Svg/Svg';

export const IconCross: ForwardRefExoticComponent<SvgIconProps> = forwardRef(({
    className = '',
}, ref: Ref<SVGSVGElement>): ReactElement => (
    <svg ref={ref} viewBox="0 0 100 100" className={`icon ${className}`}>
        <line className="icon__stroke" x1="20.4" y1="79.6" x2="79.6" y2="20.4" />
        <line className="icon__stroke" x1="20.4" y1="20.4" x2="79.6" y2="79.6" />
    </svg>
));
