'use client';

import { useEffect, useState } from 'react';

interface GeolocationCoords {
    latitude: number;
    longitude: number;
}

interface GeolocationState {
    geolocation: GeolocationCoords | null;
    error: string | null;
}

export const useGeolocation = (): GeolocationState => {
    const [geolocation, setGeolocation] = useState<GeolocationState>({
        geolocation: null,
        error: null,
    });

    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setGeolocation({
                        geolocation: {
                            latitude: position.coords.latitude,
                            longitude: position.coords.longitude,
                        },
                        error: null,
                    });
                },
                (error) => {
                    setGeolocation({
                        geolocation: null,
                        error: `Error obtaining location: ${error.message}`,
                    });
                },
            );
        } else {
            setGeolocation({
                geolocation: null,
                error: 'Geolocation is not supported by this browser.',
            });
        }
    }, []);

    return geolocation;
};

export default useGeolocation;
