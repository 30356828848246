'use client';

import { useCallback } from 'react';

import { usePathname } from 'next/navigation';

import { useLocale } from '@/context/LocaleContext';
import { defaultLocale, Language } from '@/entities/Locale/Locale';
import { isSSR } from '@/helpers';
import { getTranslation, TranslatorFunction } from '@/helpers/trans';

const useTrans = (): TranslatorFunction => {
    const { language } = useLocale();
    const pathname = usePathname();

    return useCallback<TranslatorFunction>((...args) => {
        if (!isSSR) {
            return getTranslation(language, ...args);
        }

        const routeLanguage = pathname
            ? pathname.split('/')[1] as Language
            : defaultLocale.language;

        const ssrLanguage = Object.values(Language).includes(routeLanguage)
            ? routeLanguage
            : defaultLocale.language;

        return getTranslation(ssrLanguage, ...args);
    }, [language]);
};

export default useTrans;
