/* eslint-disable max-len */
import {
    forwardRef,
    ForwardRefExoticComponent,
    ReactElement,
    Ref,
} from 'react';

import { SvgIconProps } from '@/entities/Svg/Svg';

export const IconBoxHelp: ForwardRefExoticComponent<SvgIconProps> = forwardRef(({
    className = '',
    accentClassName = '',
}, ref: Ref<SVGSVGElement>): ReactElement => (
    <svg ref={ref} viewBox="0 0 100 100" className={`icon ${className}`}>
        <rect className={accentClassName} x="16.7" y="16.7" width="66.7" height="66.7" />
        <path className="icon__box-stroke" d="M39.8,39.5c0-1-.4-4.2,1.5-5.8s5.3-1.6,8.2-1.6h3.7c4.2,0,7.1,3.9,7.1,6.5s0,4.4-2.4,6.7-7.8,4.7-7.8,4.7v8.3" />
        <line className="icon__box-stroke icon__box-stroke--rounded" x1="50.1" y1="66.7" x2="50.1" y2="67.4" />
    </svg>
));
