import { FC, PropsWithChildren, ReactElement } from 'react';

import './Page.scss';

interface PageProps {
    className?: string;
}

export const Page: FC<PropsWithChildren<PageProps>> = ({
    className = '',
    children,
}): ReactElement => (
    <main className={`page ${className}`}>
        {children}
    </main>
);
