/* eslint-disable max-len */
import {
    forwardRef,
    ForwardRefExoticComponent,
    ReactElement,
    Ref,
} from 'react';

import { SvgIconProps } from '@/entities/Svg/Svg';

export const IconVisibilityOff: ForwardRefExoticComponent<SvgIconProps> = forwardRef(({
    className = '',
}, ref: Ref<SVGSVGElement>): ReactElement => (
    <svg ref={ref} viewBox="0 0 100 100" className={`icon ${className}`}>
        <path className="icon__stroke" d="M82.7,62.4c5.6-6.5,8.5-12.4,8.5-12.4,0,0-14.3-29.2-41.2-29.2s-12.6,1.8-17.8,4.5" />
        <path className="icon__stroke" d="M17.2,37.4c-5.8,6.6-8.8,12.6-8.8,12.6,0,0,14.6,29.2,41.6,29.2s12.7-1.8,17.9-4.6" />
        <line className="icon__stroke" x1="3.5" y1="15.8" x2="96.4" y2="84.2" />
        <path className="icon__stroke" d="M33.4,49.4c0,.2,0,.4,0,.6,0,8.4,7.4,15.2,16.6,15.2s2.9-.2,4.3-.5" />
        <path className="icon__stroke" d="M66.5,50.5c0-.2,0-.3,0-.5,0-8.4-7.4-15.2-16.6-15.2s-2.9.2-4.2.5" />
    </svg>
));
