/* eslint-disable max-len */
import {
    forwardRef,
    ForwardRefExoticComponent,
    ReactElement,
    Ref,
} from 'react';

import { SvgIconProps } from '@/entities/Svg/Svg';

export const IconCartTruck: ForwardRefExoticComponent<SvgIconProps> = forwardRef(({
    className = '',
    accentClassName = '',
}, ref: Ref<SVGSVGElement>): ReactElement => (
    <svg ref={ref} viewBox="0 0 100 100" className={`icon ${className}`}>
        <path className={accentClassName} d="M17.8,24h34.1s0,6.7,0,6.7h-25.8v5.4c.3-.3.6-.6.9-.8s.7-.5,1.2-.6c.4-.2,1-.3,1.6-.4h2.2s10.9,0,10.9,0c1.7,0,3.2.2,4.5.5s2.4.8,3.3,1.5,1.6,1.6,2.1,2.8.7,2.6.7,4.3v2c0,2.4-.3,4.3-.9,5.6-.6,1.4-1.6,2.4-2.8,3.1-1.2.7-2.8,1.1-4.7,1.3s-4.1.3-6.6.3h-7.5c-2.4,0-4.4,0-6.2-.3-1.8-.2-3.2-.5-4.4-1.1s-2-1.4-2.6-2.5-.9-2.6-.9-4.4v-1.8h8.8c0,.7,0,1.2.2,1.6,0,.4.4.8.7,1,.4.3.9.4,1.6.5s1.6.2,2.7.2h9c1.1,0,2,0,2.6-.2s1.1-.4,1.5-.8c.3-.3.5-.8.6-1.3s.2-1.2.2-1.9,0-1.6-.3-2.1c-.2-.5-.5-.9-.9-1.2s-1-.4-1.7-.5h-2.7s-10.1,0-10.1,0c-.7,0-1.4.2-1.9.5s-.8.9-.9,1.6h-8.3v-18.8s-.2,0-.2,0v-.2Z" />
        <polyline className="icon__stroke" points="15.3 73.3 4.6 73.3 4.6 12.8 65 12.8 65 73.3 39.5 73.3" />
        <circle className="icon__stroke" cx="27.5" cy="75.6" r="12.2" />
        <circle className="icon__stroke" cx="77.3" cy="75.6" r="12.2" />
        <polyline className="icon__stroke" points="65 27.6 80.2 27.6 95.4 45.8 95.4 73.3 89.5 73.3" />
        <line className="icon__stroke" x1="65" y1="48.3" x2="95.4" y2="48.3" />
    </svg>
));
