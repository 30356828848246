import {
    forwardRef,
    ForwardRefExoticComponent,
    ReactElement,
    Ref,
} from 'react';

import {
    IconArrowLeft,
    IconArrowRight,
    IconArrowShortLeft,
    IconArrowShortRight,
    IconAudioOff,
    IconAudioOn,
    IconBoxCheck,
    IconBoxHelp,
    IconBoxWarning,
    IconCart,
    IconCartBag,
    IconCartTruck,
    IconCartWagon,
    IconCheck,
    IconChevronDown,
    IconChevronLeft,
    IconChevronRight,
    IconChevronUp,
    IconCross,
    IconDashboard,
    IconDelivery,
    IconEdit,
    IconFacebook,
    IconFilter,
    IconGrid,
    IconHamburger,
    IconHappy,
    IconHeart,
    IconInstagram,
    IconLamp,
    IconLinkedIn,
    IconLocation,
    IconLock,
    IconLogout,
    IconLoodsLocation,
    IconMap,
    IconMinus,
    IconOrders,
    IconPause,
    IconPinterest,
    IconPlay,
    IconPlus,
    IconSearch,
    IconSquare,
    IconStar,
    IconTikTok,
    IconUser,
    IconVisibilityOff,
    IconVisibilityOn,
    IconWayfindingCta,
    IconWayfindingDownload,
    IconWayfindingEnter,
    IconWayfindingLeft,
    IconWayfindingRight,
    IconWayfindingUp,
    IconYouTube,
} from '@/components/Icon/set';
import { SvgIconProps } from '@/entities/Svg/Svg';

import './Icon.scss';

const icons = {
    'arrow-left': IconArrowLeft,
    'arrow-right': IconArrowRight,
    'arrow-short-left': IconArrowShortLeft,
    'arrow-short-right': IconArrowShortRight,
    'audio-off': IconAudioOff,
    'audio-on': IconAudioOn,
    'box-check': IconBoxCheck,
    'box-help': IconBoxHelp,
    'box-warning': IconBoxWarning,
    cart: IconCart,
    'cart-bag': IconCartBag,
    'cart-truck': IconCartTruck,
    'cart-wagon': IconCartWagon,
    check: IconCheck,
    'chevron-down': IconChevronDown,
    'chevron-left': IconChevronLeft,
    'chevron-right': IconChevronRight,
    'chevron-up': IconChevronUp,
    cross: IconCross,
    dashboard: IconDashboard,
    delivery: IconDelivery,
    edit: IconEdit,
    facebook: IconFacebook,
    filter: IconFilter,
    grid: IconGrid,
    hamburger: IconHamburger,
    happy: IconHappy,
    heart: IconHeart,
    instagram: IconInstagram,
    lamp: IconLamp,
    linkedin: IconLinkedIn,
    location: IconLocation,
    lock: IconLock,
    logout: IconLogout,
    'loods-location': IconLoodsLocation,
    map: IconMap,
    minus: IconMinus,
    orders: IconOrders,
    pause: IconPause,
    pinterest: IconPinterest,
    play: IconPlay,
    plus: IconPlus,
    search: IconSearch,
    square: IconSquare,
    star: IconStar,
    tiktok: IconTikTok,
    user: IconUser,
    'visibility-off': IconVisibilityOff,
    'visibility-on': IconVisibilityOn,
    'wayfinding-cta': IconWayfindingCta,
    'wayfinding-download': IconWayfindingDownload,
    'wayfinding-enter': IconWayfindingEnter,
    'wayfinding-left': IconWayfindingLeft,
    'wayfinding-right': IconWayfindingRight,
    'wayfinding-up': IconWayfindingUp,
    youtube: IconYouTube,
};

export type IconName = keyof typeof icons;

interface IconProps extends SvgIconProps {
    name: IconName;
}

export const Icon: ForwardRefExoticComponent<IconProps> = forwardRef(({
    name,
    className = '',
    accentClassName = '',
    pathClassName = '',
}, ref: Ref<SVGSVGElement>): ReactElement | null => {
    const IconComponent = icons[name];

    if (!IconComponent) {
        return null;
    }

    return (
        <IconComponent
            ref={ref}
            className={className}
            accentClassName={accentClassName}
            pathClassName={pathClassName}
        />
    );
});
