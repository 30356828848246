/* eslint-disable max-len */
import {
    forwardRef,
    ForwardRefExoticComponent,
    ReactElement,
    Ref,
} from 'react';

import { SvgIconProps } from '@/entities/Svg/Svg';

export const IconLock: ForwardRefExoticComponent<SvgIconProps> = forwardRef(({
    className = '',
}, ref: Ref<SVGSVGElement>): ReactElement => (
    <svg ref={ref} viewBox="0 0 100 100" className={`icon ${className}`}>
        <path className="icon__stroke" d="M50,56.2v18.8M31.2,41.7H14.6v47.9h70.8v-47.9h-16.7M31.2,41.7v-14.6s0-16.7,18.8-16.7,18.8,16.7,18.8,16.7v14.6M31.2,41.7h37.5" />
    </svg>
));
