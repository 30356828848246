import {
    FC,
    forwardRef,
    ImgHTMLAttributes,
    ReactElement,
    RefAttributes,
} from 'react';

import { ImageFormat, ImageFormatSize } from '@/entities/Media/Media';

import './Picture.scss';

export interface PictureProps extends ImgHTMLAttributes<HTMLPictureElement>, RefAttributes<HTMLPictureElement> {
    formatOverride?: ImageFormatSize;
    formats?: Partial<Record<ImageFormatSize, ImageFormat>>;
    className?: string;
    imageClassName?: string;
}

export const Picture: FC<PictureProps> = forwardRef(({
    formatOverride,
    loading,
    formats = {},
    src,
    alt,
    className = '',
    imageClassName = '',
    ...pictureProps
}, ref): ReactElement => {
    const forcedFormat = formatOverride
        ? formats[formatOverride]
        : undefined;

    const sources = Object.values(formats).filter(Boolean);

    return (
        <picture
            {...pictureProps}
            ref={ref}
            className={`picture ${className}`}
        >
            {!forcedFormat && sources.map(source => (
                <source key={source.srcSet} {...source} />
            ))}

            {src && (
                // eslint-disable-next-line @next/next/no-img-element
                <img
                    loading={loading}
                    src={forcedFormat?.srcSet || src}
                    alt={alt}
                    className={`picture__image ${imageClassName}`}
                />
            )}
        </picture>
    );
});
