/* eslint-disable max-len */
import {
    forwardRef,
    ForwardRefExoticComponent,
    ReactElement,
    Ref,
} from 'react';

import { SvgIconProps } from '@/entities/Svg/Svg';

export const IconHamburger: ForwardRefExoticComponent<SvgIconProps> = forwardRef(({
    className = '',
}, ref: Ref<SVGSVGElement>): ReactElement => (
    <svg ref={ref} viewBox="0 0 100 100" className={`icon ${className}`}>
        <line className="icon__stroke" x1=".1" y1="12.8" x2="100" y2="12.8" />
        <line className="icon__stroke" x1=".1" y1="50" x2="100" y2="50" />
        <line className="icon__stroke" x1=".1" y1="87.2" x2="100" y2="87.2" />
    </svg>
));
