/* eslint-disable max-len */
import {
    forwardRef,
    ForwardRefExoticComponent,
    ReactElement,
    Ref,
} from 'react';

import { SvgIconProps } from '@/entities/Svg/Svg';

export const IconLinkedIn: ForwardRefExoticComponent<SvgIconProps> = forwardRef(({
    className = '',
}, ref: Ref<SVGSVGElement>): ReactElement => (
    <svg ref={ref} viewBox="0 0 14 24" className={`icon ${className}`}>
        <path
            d="M13 5C13.5312 5 14 5.46875 14 6.03125V18C14 18.5625 13.5312 19 13 19H0.96875C0.4375 19 0 18.5625 0 18V6.03125C0 5.46875 0.4375 5 0.96875 5H13ZM4.21875 17V10.3438H2.15625V17H4.21875ZM3.1875 9.40625C3.84375 9.40625 4.375 8.875 4.375 8.21875C4.375 7.5625 3.84375 7 3.1875 7C2.5 7 1.96875 7.5625 1.96875 8.21875C1.96875 8.875 2.5 9.40625 3.1875 9.40625ZM12 17V13.3438C12 11.5625 11.5938 10.1562 9.5 10.1562C8.5 10.1562 7.8125 10.7188 7.53125 11.25H7.5V10.3438H5.53125V17H7.59375V13.7188C7.59375 12.8438 7.75 12 8.84375 12C9.90625 12 9.90625 13 9.90625 13.75V17H12Z"
        />
    </svg>
));
