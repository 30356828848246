export type DateResource = string;
export type DateIsoString = string;

export interface DateFormatOptions extends Intl.DateTimeFormatOptions {
    dateStyle?: 'full' | 'long' | 'medium' | 'short';
    timeStyle?: 'full' | 'long' | 'medium' | 'short';
}

export const defaultDateIsoString = new Date().toISOString();

export const defaultDateFormatOptions: DateFormatOptions = {
    dateStyle: 'medium',
};
