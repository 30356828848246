import React, { FC } from 'react';

import { Icon, IconName } from '@/components';

import './ShoppingCartCount.scss';

interface ShoppingCartCountProps {
    productCount: number;
    className?: string;
}

export const ShoppingCartCount: FC<ShoppingCartCountProps> = ({ productCount, className = '' }) => {
    const getCartIcon = (): IconName => {
        if (productCount <= 4) {
            return 'cart-bag';
        }

        if (productCount <= 9) {
            return 'cart-wagon';
        }

        return 'cart-truck';
    };

    return (
        <div>
            <div className={`shopping-cart-count ${className}`}>
                {productCount > 0 && (
                    <div className="shopping-cart-count__amount">
                        {productCount}
                    </div>
                )}
                <Icon name={getCartIcon()} className="shopping-cart-count__icon" />
            </div>
        </div>
    );
};
