import { FC, ReactElement } from 'react';

import classNames from 'classnames';

import { QuoteSize } from '@/entities/Quote/Quote';

import './Quote.scss';

export interface QuoteProps {
    text: string;
    size?: QuoteSize;
    className?: string;
}

export const Quote: FC<QuoteProps> = ({
    text,
    size = QuoteSize.small,
    className = '',
}): ReactElement => {
    const quoteClassNames = classNames('quote', {
        'quote--is-large': size === QuoteSize.large,
    }, className);

    return (
        <p className={quoteClassNames}>
            {text}
        </p>
    );
};
