'use client';

import {
    CSSProperties,
    forwardRef,
    ForwardRefExoticComponent,
    ReactElement,
    Ref,
    useRef,
} from 'react';

import classNames from 'classnames';

import { Icon, IconName } from '@/components/Icon/Icon';
import { Button, ButtonProps } from '@/compositions/@buttons/Button/Button';
import { HorizontalAlignment } from '@/entities/Alignment/Alignment';
import useElementSize from '@/hooks/useElementSize';

import './IconButton.scss';

export interface IconButtonProps extends ButtonProps {
    icon: IconName;
    iconPos?: HorizontalAlignment;
    hideLabel?: boolean;
    iconClassName?: string;
}

export const IconButton: ForwardRefExoticComponent<IconButtonProps> = forwardRef(({
    hasAnimation,
    icon,
    iconPos = HorizontalAlignment.left,
    text,
    hideLabel,
    className = '',
    iconClassName = '',
    ...iconButtonProps
}, ref: Ref<HTMLButtonElement>): ReactElement => {
    const iconRef = useRef<SVGSVGElement>(null);
    const { width: iconWidth } = useElementSize(iconRef);

    const buttonClassNames = classNames('icon-button', {
        'icon-button--has-reveal-animation': hasAnimation && !hideLabel,
        'icon-button--has-push-animation': hasAnimation && hideLabel,
        [`icon-button--align-${iconPos}`]: iconPos,
    }, className);

    const cssVariables = {
        '--icon-button-icon-width': `${iconWidth}px`,
    } as CSSProperties;

    return (
        <Button
            {...iconButtonProps}
            ref={ref}
            text={text}
            style={cssVariables}
            className={buttonClassNames}
            labelClassName="icon-button__label"
        >
            <div className="icon-button__icon-wrapper">
                <Icon
                    ref={iconRef}
                    name={icon}
                    className={`icon-button__icon ${iconClassName}`}
                />

                {hasAnimation && hideLabel && (
                    <Icon
                        name={icon}
                        className={`icon-button__icon icon-button__icon-copy ${iconClassName}`}
                    />
                )}
            </div>

            {!hideLabel && text}
        </Button>
    );
});
