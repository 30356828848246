import { FC, ReactElement } from 'react';

import { TabItem } from '@/compositions/TabList/subcomponents';
import { Tab } from '@/entities/Tab/Tab';

import './TabList.scss';

interface TabListProps {
    tabs: Tab[];
    activeTab: string;
    onTabSelect: (value: string) => void;
    className?: string;
}

export const TabList: FC<TabListProps> = ({
    tabs,
    activeTab,
    onTabSelect,
    className = '',
}): ReactElement => (
    <ul className={`tab-list ${className}`}>
        {tabs.map(tab => (
            <TabItem
                {...tab}
                key={tab.value}
                isActive={tab.value === activeTab}
                onClick={onTabSelect}
            />
        ))}
    </ul>
);
