/* eslint-disable max-len */
import {
    forwardRef,
    ForwardRefExoticComponent,
    ReactElement,
    Ref,
} from 'react';

import { SvgIconProps } from '@/entities/Svg/Svg';

export const IconAudioOff: ForwardRefExoticComponent<SvgIconProps> = forwardRef(({
    className = '',
}, ref: Ref<SVGSVGElement>): ReactElement => (
    <svg ref={ref} viewBox="0 0 100 100" className={`icon ${className}`}>
        <polyline className="icon__stroke" points="53 66.2 53 85.4 49.5 85.4 25.7 63.4 6.7 63.4 6.7 36.6 17.3 36.6" />
        <polyline className="icon__stroke" points="37.4 25.9 49.5 14.6 53 14.7 53 38.8" />
        <path className="icon__stroke" d="M72.8,55.2c.7-1.6,1-3.3,1-5.1,0-4.4-2.1-8.3-5.4-10.7" />
        <path className="icon__stroke" d="M85,65.3c2.6-4.5,4.1-9.7,4.1-15.2,0-9.5-4.4-18.1-11.4-23.6" />
        <line className="icon__stroke" x1="2.3" y1="11.3" x2="97.8" y2="89.1" />
    </svg>
));
