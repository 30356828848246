/* eslint-disable max-len */
import {
    forwardRef,
    ForwardRefExoticComponent,
    ReactElement,
    Ref,
} from 'react';

import { SvgIconProps } from '@/entities/Svg/Svg';

export const IconUser: ForwardRefExoticComponent<SvgIconProps> = forwardRef(({
    className = '',
}, ref: Ref<SVGSVGElement>): ReactElement => (
    <svg ref={ref} viewBox="0 0 100 100" className={`icon ${className}`}>
        <circle className="icon__stroke" cx="50" cy="29" r="18.1" />
        <path className="icon__stroke" d="M42.3,60.8h15.5c14.1,0,25.5,11.4,25.5,25.5v2.8H16.8v-2.8c0-14.1,11.4-25.5,25.5-25.5Z" />
    </svg>
));
