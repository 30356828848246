/* eslint-disable max-len */
import {
    forwardRef,
    ForwardRefExoticComponent,
    ReactElement,
    Ref,
} from 'react';

import { SvgIconProps } from '@/entities/Svg/Svg';

export const IconWayfindingUp: ForwardRefExoticComponent<SvgIconProps> = forwardRef(({
    className = '',
    pathClassName = '',
}, ref: Ref<SVGSVGElement>): ReactElement => (
    <svg ref={ref} viewBox="0 0 100 100" className={`icon ${className}`}>
        <path className={`icon__stroke ${pathClassName}`} d="M43.4,35v55.8h12.5v-57.2l.7.7,21.5,21.5,8.8-8.8L49.9,9.8,12.8,47l8.8,8.8,21.1-21.1.7-.7v1Z" />
    </svg>
));
