/* eslint-disable max-len */
import {
    forwardRef,
    ForwardRefExoticComponent,
    ReactElement,
    Ref,
} from 'react';

import { SvgIconProps } from '@/entities/Svg/Svg';

export const IconHeart: ForwardRefExoticComponent<SvgIconProps> = forwardRef(({
    className = '',
    pathClassName = '',
}, ref: Ref<SVGSVGElement>): ReactElement => (
    <svg ref={ref} viewBox="0 0 100 100" className={`icon ${className}`}>
        <path
            className={`icon__stroke ${pathClassName}`}
            d="M77.5,10.9c-12.8-2.3-21.4,7.4-27.5,13.5-6.1-6.1-14.7-15.9-27.5-13.5C11.6,12.9-1.5,26.1,4.9,41.8c4.6,11.3,35.5,39.2,45.1,48.8,9.6-9.6,40.4-37.5,45.1-48.8,6.4-15.7-6.7-28.9-17.6-30.8Z"
        />
    </svg>
));
