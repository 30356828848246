'use client';

import React, { FC, ReactElement } from 'react';

import { LinkIconButton } from '@/compositions/@buttons/LinkIconButton/LinkIconButton';
import { Picture } from '@/compositions/Picture/Picture';
import { WayfindingWrapper } from '@/compositions/WayfindingWrapper/WayfindingWrapper';
import { HorizontalAlignment } from '@/entities/Alignment/Alignment';
import { Article } from '@/entities/Article/Article';
import { WayfindingType } from '@/entities/Wayfinding/Wayfinding';
import useFormatDate from '@/hooks/useFormatDate';
import useTrans from '@/hooks/useTrans';

import './ArticleCard.scss';

export interface ArticleCardProps {
    article: Article;
    className?: string;
}

export const ArticleCard: FC<ArticleCardProps> = ({
    article,
    className = '',
}): ReactElement => {
    const trans = useTrans();
    const formatDate = useFormatDate();

    const formattedPublishedDate = formatDate(article.publishedDate, {
        day: '2-digit',
        month: 'short',
        year: '2-digit',
    });

    return (
        <WayfindingWrapper
            id={`${article.id}-card`}
            type={WayfindingType.enter}
        >
            <div className={`article-card ${className}`}>
                <div className="article-card__image-wrapper">
                    <Picture {...article.image} className="article-card__image" />
                </div>

                <p className="article-card__details-wrapper">
                    <span className="article-card__tag">
                        {article.tag.name}
                    </span>

                    <span className="article-card__published-date">
                        {formattedPublishedDate}
                    </span>
                </p>

                <h3 className="article-card__title">
                    {article.title}
                </h3>

                <LinkIconButton
                    href={`/article/${article.slug}`}
                    iconPos={HorizontalAlignment.right}
                    icon="arrow-short-right"
                    text={trans('compositions.articleCard.readMore')}
                    className="article-card__link-button"
                    labelClassName="article-card__link-label"
                    iconClassName="article-card__link-icon"
                />
            </div>
        </WayfindingWrapper>
    );
};
