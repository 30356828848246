'use client';

import {
    CSSProperties,
    FC,
    PropsWithChildren,
    ReactElement,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';

import classNames from 'classnames';
import { useWindowSize } from 'react-use';

import { RootPortal } from '@/components';
import { PageCoords } from '@/entities/Window/Window';
import usePointerPosition from '@/hooks/usePointerPosition';
import useResizeObserver from '@/hooks/useResizeObserver';

import './TooltipWrapper.scss';

export interface TooltipWrapperProps {
    followCursor?: boolean;
    id: string;
    text: string;
    className?: string;
    tooltipClassName?: string;
}

export const TooltipWrapper: FC<PropsWithChildren<TooltipWrapperProps>> = ({
    followCursor,
    id,
    text,
    className = '',
    tooltipClassName = '',
    children,
}): ReactElement => {
    const tooltipWrapperRef = useRef<HTMLDivElement>(null);

    const { width, height } = useWindowSize();
    const pointerPosition = usePointerPosition();
    const wrapperRect = useResizeObserver(tooltipWrapperRef, [width, height]);

    const [isRevealed, setIsRevealed] = useState<boolean>(false);
    const [tooltipPosition, setTooltipPosition] = useState<PageCoords | undefined>();

    useEffect((): void => {
        if (followCursor || !wrapperRect) {
            setTooltipPosition(undefined);
            return;
        }

        setTooltipPosition({
            x: wrapperRect.x + (wrapperRect.width / 2),
            y: wrapperRect.y + window.scrollY,
        });
    }, [followCursor, wrapperRect, width]);

    const handleRevealTooltip = (): void => setIsRevealed(true);
    const handleHideTooltip = (): void => setIsRevealed(false);
    const handleFocus = (): void => setIsRevealed(isRevealed || !followCursor);

    const cssVariables = useMemo(() => ({
        '--tooltip-x': `${tooltipPosition?.x || pointerPosition.x}px`,
        '--tooltip-y': `${tooltipPosition?.y || pointerPosition.y}px`,
    } as CSSProperties), [tooltipPosition, pointerPosition]);

    const tooltipClassNames = classNames('tooltip-wrapper__tooltip', {
        'tooltip-wrapper__tooltip--is-revealed': isRevealed,
        'tooltip-wrapper__tooltip--is-fixed': followCursor,
        [`${tooltipClassName}--is-revealed`]: tooltipClassName && isRevealed,
    }, tooltipClassName);

    return (
        <div
            ref={tooltipWrapperRef}
            aria-describedby={id}
            aria-label={text}
            onPointerEnter={handleRevealTooltip}
            onPointerLeave={handleHideTooltip}
            onFocus={handleFocus}
            onBlur={handleHideTooltip}
            className={`tooltip-wrapper ${className}`}
        >
            <RootPortal>
                <span
                    id={id}
                    role="tooltip"
                    style={cssVariables}
                    className={tooltipClassNames}
                >
                    {text}
                </span>
            </RootPortal>

            {children}
        </div>
    );
};
