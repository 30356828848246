/* eslint-disable max-len */
import {
    forwardRef,
    ForwardRefExoticComponent,
    ReactElement,
    Ref,
} from 'react';

import { SvgIconProps } from '@/entities/Svg/Svg';

export const IconAudioOn: ForwardRefExoticComponent<SvgIconProps> = forwardRef(({
    className = '',
}, ref: Ref<SVGSVGElement>): ReactElement => (
    <svg ref={ref} viewBox="0 0 100 100" className={`icon ${className}`}>
        <polygon className="icon__stroke" points="53 85.4 49.5 85.4 25.7 63.4 6.7 63.4 6.7 36.6 25.7 36.6 49.5 14.6 53 14.7 53 85.4" />
        <path className="icon__stroke" d="M68.5,60.7c3.2-2.4,5.3-6.3,5.3-10.7s-2.1-8.3-5.4-10.7" />
        <path className="icon__stroke" d="M77.8,73.6c6.9-5.5,11.3-14,11.3-23.5s-4.4-18.1-11.4-23.6" />
    </svg>
));
