/* eslint-disable max-len */
import {
    forwardRef,
    ForwardRefExoticComponent,
    ReactElement,
    Ref,
} from 'react';

import { SvgIconProps } from '@/entities/Svg/Svg';

export const IconWayfindingDownload: ForwardRefExoticComponent<SvgIconProps> = forwardRef(({
    className = '',
    pathClassName = '',
}, ref: Ref<SVGSVGElement>): ReactElement => (
    <svg ref={ref} viewBox="0 0 100 100" className={`icon ${className}`}>
        <path className={`icon__stroke ${pathClassName}`} d="M43.8,48.7V6.2h12.5v43.8l.7-.7,21.5-21.5,8.8,8.8-37.1,37.1L13.1,36.7l8.8-8.8,21.1,21.1.7.7v-1ZM22.6,94.2v-12.5h55.8v12.5H22.6Z" />
    </svg>
));
