'use client';

import {
    FC,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import classNames from 'classnames';
import ReactSelect, { OnChangeValue } from 'react-select';

import { IconChevronDown } from '@/components/Icon/set';
import { FormOption } from '@/entities/Form/Form';
import useTrans from '@/hooks/useTrans';

import './Select.scss';

interface SelectProps {
    isLoading: boolean;
    isLoadingMessage?: string;
    isSearchable?: boolean;
    name: string;
    value?: OnChangeValue<FormOption, false>;
    options: FormOption[];
    required?: boolean;
    disabled?: boolean;
    placeholder?: string;
    onChange: (selected: FormOption) => void;
    className?: string;
}

export const Select: FC<SelectProps> = ({
    isLoading,
    isLoadingMessage,
    isSearchable,
    name,
    value,
    options,
    required,
    disabled,
    placeholder,
    onChange,
    className = '',
}): ReactElement | null => {
    const trans = useTrans();

    const [isMounted, setIsMounted] = useState<boolean>(false);

    useEffect((): void => {
        setIsMounted(true);
    }, []);

    const getLoadingMessage = (): string => isLoadingMessage || trans('components.select.loadingMessage');
    const getNoOptionsMessage = (): string => trans('components.select.nullState');

    const handleChange = (selectValue: OnChangeValue<FormOption, false>): void => {
        onChange(selectValue as FormOption);
    };

    const selectClassNames = classNames('select', {
        'select--is-disabled': disabled,
    }, className);

    // Unfortunate solution to SSR warning in console. Component may only render on client.
    if (!isMounted) {
        return null;
    }

    return (
        <ReactSelect
            isLoading={isLoading}
            loadingMessage={getLoadingMessage}
            isSearchable={isSearchable}
            name={name}
            value={value}
            options={options}
            required={required}
            isDisabled={disabled}
            placeholder={placeholder || trans('components.select.placeholder')}
            noOptionsMessage={getNoOptionsMessage}
            components={{ DropdownIndicator: IconChevronDown }}
            onChange={handleChange}
            classNamePrefix="select"
            className={selectClassNames}
        />
    );
};
