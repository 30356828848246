import {
    forwardRef,
    ForwardRefExoticComponent,
    InputHTMLAttributes,
    ReactElement,
    Ref,
    RefAttributes,
} from 'react';

import classNames from 'classnames';

import { Icon, IconName } from '@/components/Icon/Icon';

import './Input.scss';

export interface InputProps extends InputHTMLAttributes<HTMLInputElement>, RefAttributes<HTMLInputElement> {
    hasControls?: boolean;
    hasUnderline?: boolean;
    icon?: IconName;
    error?: string;
    fieldClassName?: string;
}

export const Input: ForwardRefExoticComponent<InputProps> = forwardRef(({
    hasControls,
    hasUnderline,
    type,
    icon,
    error,
    className = '',
    fieldClassName = '',
    ...inputProps
}, ref: Ref<HTMLInputElement>): ReactElement => {
    const inputClassNames = classNames('input', {
        'input--has-underline': hasUnderline,
        'input--has-controls': hasControls,
    }, className);

    const inputFieldClassNames = classNames('input__field', {
        'input__field--has-icon': icon,
        'input__field--has-error': error,
    }, fieldClassName);

    return (
        <div className={inputClassNames}>
            {icon && (
                <Icon name={icon} className="input__icon" />
            )}

            <input
                {...inputProps}
                ref={ref}
                type={type || 'text'}
                className={inputFieldClassNames}
            />

            {error && (
                <Icon name="box-warning" className="input__error-icon" />
            )}
        </div>
    );
});
