'use client';

import { FC, FormEvent, useState } from 'react';

import dynamic from 'next/dynamic';

import { Button } from '@/compositions/@buttons/Button/Button';
import { Input } from '@/compositions/@inputs/Input/Input';
import { InputLabelWrapper } from '@/compositions/@inputs/InputLabelWrapper/InputLabelWrapper';
import {
    MapLocation,
    MapProps,
    OpenStreetMapSearchResult,
    ShopMapLocation,
} from '@/entities/Map/Map';
import translate from '@/helpers/trans';
import useGeolocation from '@/hooks/useGeolocation';
import useTrans from '@/hooks/useTrans';

import { IconButton } from '../@buttons/IconButton/IconButton';

import './ShopLocator.scss';

const Map = dynamic<MapProps>(() => import('../Map/Map').then((mod) => mod.Map), {
    ssr: false,
    loading: () => <p>{translate('common.loading')}</p>,
});

export const getMapLocationBySearchTerm = async (
    searchTerm: string,
    limit: number = 5,
): Promise<MapLocation[]> => {
    const params = {
        q: searchTerm,
        format: 'geojson',
        layers: 'address',
        limit: limit.toString(),
        countrycodes: 'nl',
        'accept-language': 'nl',
    };

    const response = await fetch(
        `https://nominatim.openstreetmap.org/search?${new URLSearchParams(params)}`,
    );
    const data: OpenStreetMapSearchResult = await response.json();

    const results = data.features?.map((feature) => ({
        label: feature.properties.display_name
            .split(', Nederland').join(''), // Remove country name
        location: {
            latitude: feature.geometry.coordinates[1],
            longitude: feature.geometry.coordinates[0],
        },
    })) ?? [];

    // Return with unique names only
    return results.filter((result, index, self) => self.findIndex((r) => r.label === result.label) === index);
};

interface ShopLocatorProps {
    shopLocations: ShopMapLocation[];
    onMarkerClick?: (location: MapLocation) => void;
    className?: string;
}

export const ShopLocator: FC<ShopLocatorProps> = ({
    shopLocations = [],
    onMarkerClick = null,
    className = '',
}) => {
    const trans = useTrans();
    const { geolocation, error } = useGeolocation();

    const [searchTerm, setSearchTerm] = useState<string>('');
    const [searchResults, setSearchResults] = useState<MapLocation[]>([]);
    const [selectedLocation, setSelectedLocation] = useState<MapLocation | null>(null);

    const handleSearchTermChange = (event: FormEvent<HTMLInputElement>) => {
        setSearchTerm(event.currentTarget.value);

        // Clear search results when input is emptied
        if (!event.currentTarget.value) {
            setSearchResults([]);
            setSelectedLocation(null);
        }
    };

    const handleSearchFormSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const results = await getMapLocationBySearchTerm(searchTerm);
        setSearchResults(results);

        // If only one result, select it
        if (results.length === 1) {
            setSelectedLocation(results[0]);
        }
    };

    const handleSearchResultChange = (searchResult: MapLocation) => {
        setSelectedLocation(searchResult);

        // Hide search results once a result is selected
        setSearchResults([]);
    };

    const handleMarkerClick = (location: MapLocation) => {
        setSelectedLocation(location as ShopMapLocation);
        onMarkerClick?.(location as ShopMapLocation);
    };

    const handleGetCurrentLocationClick = () => {
        if (geolocation) {
            setSelectedLocation({
                label: 'Current Location',
                location: {
                    latitude: geolocation.latitude,
                    longitude: geolocation.longitude,
                },
            });
        } else if (error) {
            console.error(error);
        }
    };

    return (
        <div className={`shop-locator ${className}`}>
            <Map
                center={selectedLocation?.location}
                zoom={selectedLocation ? 10 : 7}
                markers={shopLocations}
                zoomControl={false}
                onMarkerClick={handleMarkerClick}
                className="shop-locator__map"
            />

            <h2 className="shop-locator__title">Bezoek onze winkels</h2>

            <form
                action=""
                className="shop-locator__search-form"
                onSubmit={handleSearchFormSubmit}
            >
                <div className="shop-locator__search-input-wrapper">
                    <label
                        aria-label="Zoek op postcode of plaats"
                        className="shop-locator__search-input-label"
                    >
                        <InputLabelWrapper label="">
                            <Input
                                hasUnderline
                                type="search"
                                icon="search"
                                placeholder={trans('compositions.shopLocator.inputPlaceholder')}
                                value={searchTerm}
                                onChange={handleSearchTermChange}
                            />
                        </InputLabelWrapper>
                    </label>

                    <div className="shop-locator__search-results">
                        {searchResults?.length > 1 && searchResults.map((result) => (
                            <Button
                                key={result.label}
                                text={result.label}
                                onClick={() => handleSearchResultChange(result)}
                                className="shop-locator__search-result"
                            />
                        ))}
                    </div>
                </div>

                <IconButton
                    text={trans('compositions.shopLocator.useCurrentLocation')}
                    icon="location"
                    onClick={handleGetCurrentLocationClick}
                    className="shop-locator__current-location-button"
                />
            </form>
        </div>
    );
};
