/* eslint-disable max-len */
import {
    forwardRef,
    ForwardRefExoticComponent,
    ReactElement,
    Ref,
} from 'react';

import { SvgIconProps } from '@/entities/Svg/Svg';

export const IconBoxCheck: ForwardRefExoticComponent<SvgIconProps> = forwardRef(({
    className = '',
    accentClassName = '',
}, ref: Ref<SVGSVGElement>): ReactElement => (
    <svg ref={ref} viewBox="0 0 100 100" className={`icon ${className}`}>
        <rect className={accentClassName} x="16.7" y="16.7" width="66.7" height="66.7" />
        <path className="icon__box-stroke" d="M29.2,52.1l16.7,14.6,29.2-35.4" />
    </svg>
));
