import { FC, ReactElement } from 'react';

import { Icon } from '@/components';
import { Button, ButtonProps } from '@/compositions/@buttons/Button/Button';

import './WayfindingButton.scss';

export interface WayfindingButtonProps extends ButtonProps {
    className?: string;
}

export const WayfindingButton: FC<WayfindingButtonProps> = ({
    text,
    hideLabel,
    className = '',
    ...buttonProps
}): ReactElement => (
    <Button
        {...buttonProps}
        text={text}
        className={`wayfinding-button ${className}`}
        labelClassName="wayfinding-button__label"
    >
        {!hideLabel && text}

        <div className="wayfinding-button__icon-wrapper">
            <Icon
                name="wayfinding-enter"
                className="wayfinding-button__icon"
            />

            <div className="wayfinding-button__fill" />
        </div>
    </Button>
);
