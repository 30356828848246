'use client';

import {
    CSSProperties,
    FC,
    ReactElement,
    ReactNode,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';

import classNames from 'classnames';

import { WayfindingDirectionButton } from '@/compositions/@buttons/WayfindingDirectionButton/WayfindingDirectionButton';
import { Device } from '@/entities/Device/Device';
import { Direction } from '@/entities/Navigation/Navigation';
import { generateIdArray } from '@/helpers/array';
import { clamp } from '@/helpers/number';
import trans from '@/helpers/trans';
import useDeviceWidth from '@/hooks/useDeviceWidth';
import useElementSize from '@/hooks/useElementSize';
import useScrollbarWidth from '@/hooks/useScrollbarWidth';
import useTimeout from '@/hooks/useTimeout';

import './Slider.scss';

export interface SliderProps {
    activeIndex: number;
    maxIndex: number;
    id: string;
    items: ReactNode[];
    columns?: Partial<Record<Device, number>>;
    onChange: (index: number) => void;
    className?: string;
}

export const Slider: FC<SliderProps> = ({
    activeIndex,
    maxIndex,
    id,
    items,
    columns,
    onChange,
    className = '',
}): ReactElement => {
    const listRef = useRef<HTMLUListElement>(null);
    const { isMobile } = useDeviceWidth();
    const scrollbarWidth = useScrollbarWidth();

    const [isMounted, setIsMounted] = useState<boolean>(false);
    const [cardWidth, setCardWidth] = useState<number>(0);
    const [isResizing, setIsResizing] = useState<boolean>(false);
    const [scrollCounter, setScrollCounter] = useState<number>(0);

    const { width: listWidth } = useElementSize(listRef, [items]);

    const itemIndexes = generateIdArray(items.length);

    const columnData = useMemo((): Record<Device, number> => ({
        [Device.mobile]: 1,
        [Device.tabletPortrait]: 2,
        [Device.tabletLandscape]: 3,
        [Device.desktop]: 4,
        ...columns,
    }), [columns]);

    const handleChange = (newIndex: number): void => {
        onChange(clamp(newIndex, 0, maxIndex - 1));
    };

    const handlePrevClick = (): void => handleChange(activeIndex - 1);
    const handleNextClick = (): void => handleChange(activeIndex + 1);

    // TODO: Replace this and the timeout with scrollEnd once Safari supports it
    const handleScroll = (): void => {
        setScrollCounter((scrollCounter + 1) % 100);
    };

    useEffect((): void => {
        const firstChild = listRef.current?.firstChild as HTMLDivElement | undefined;

        if (firstChild) {
            setCardWidth(firstChild.offsetWidth);
        }

        setIsResizing(true);
    }, [listWidth]);

    useTimeout((): void => {
        setIsResizing(false);
    }, 500, [listWidth]);

    useEffect((): void => {
        if (!listWidth || !cardWidth) return;

        handleChange(activeIndex);
    }, [maxIndex, listWidth]);

    useEffect((): void => {
        // Early return to prevent scroll to active item on mount
        if (!isMounted) {
            setIsMounted(true);
            return;
        }

        if (!isMobile || !listRef.current) {
            return;
        }

        const activeItem = listRef.current.children[activeIndex];

        if (activeItem) {
            activeItem.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
                inline: 'center',
            });
        }
    }, [activeIndex]);

    useTimeout((): void => {
        if (!isMobile || !listRef.current) {
            return;
        }

        const { scrollLeft } = listRef.current;
        const scrollAdjustment = -0.5;
        const scrollPosition = (scrollLeft + 1) / cardWidth;
        const adjustedScrollPosition = scrollPosition + scrollAdjustment;

        const roundedPosition = Math.round(adjustedScrollPosition);
        const newIndex = clamp(roundedPosition, 0, items.length - 1);

        onChange(newIndex);
    }, 100, [scrollCounter]);

    const cssVariables = {
        '--scrollbar-width': `${scrollbarWidth}px`,
        '--slider-columns-mobile': columnData[Device.mobile],
        '--slider-columns-tablet-portrait': columnData[Device.tabletPortrait],
        '--slider-columns-tablet-landscape': columnData[Device.tabletLandscape],
        '--slider-columns-desktop': columnData[Device.desktop],
        '--slider-scroll-progress': `${activeIndex * cardWidth}px`,
    } as CSSProperties;

    const sliderListClassNames = classNames('slider__list', {
        'slider__list--is-resizing': isResizing,
    });

    return (
        <div style={cssVariables} className={`slider ${className}`}>
            <div className="slider__navigation-wrapper">
                <WayfindingDirectionButton
                    id={id}
                    direction={Direction.prev}
                    text={trans('common.prev')}
                    onClick={handlePrevClick}
                    className="slider__navigation-button"
                />

                <WayfindingDirectionButton
                    id={id}
                    direction={Direction.next}
                    text={trans('common.next')}
                    onClick={handleNextClick}
                    className="slider__navigation-button"
                />
            </div>

            <ul
                ref={listRef}
                onScroll={handleScroll}
                className={sliderListClassNames}
            >
                {items.map((item, index) => (
                    <li key={itemIndexes[index]} className="slider__item">
                        {item}
                    </li>
                ))}
            </ul>
        </div>
    );
};
