import { FC, ReactElement } from 'react';

import { convertNumberToCurrency } from '@/helpers/number';

import './Price.scss';

export interface PriceProps {
    amount: number;
    className?: string;
}

export const Price: FC<PriceProps> = ({
    amount,
    className = '',
}): ReactElement => {
    const formattedAmount = convertNumberToCurrency(amount);

    return (
        <p className={`price ${className}`}>
            {formattedAmount}
        </p>
    );
};
