import { FC, ReactElement } from 'react';

import './ErrorLabel.scss';

interface ErrorLabelProps {
    text: string;
    className?: string;
}

export const ErrorLabel: FC<ErrorLabelProps> = ({
    text,
    className = '',
}): ReactElement => (
    <span className={`error-label ${className}`}>
        {text}
    </span>
);
