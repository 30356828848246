import {
    forwardRef,
    ForwardRefExoticComponent,
    PropsWithChildren,
    ReactElement,
    Ref,
    RefAttributes,
} from 'react';

import './Wrapper.scss';

interface WrapperProps {
    className?: string;
}

type WrapperWithRefProps = WrapperProps & RefAttributes<HTMLDivElement>;

export const Wrapper: ForwardRefExoticComponent<PropsWithChildren<WrapperWithRefProps>> = forwardRef(({
    className = '',
    children,
}, ref: Ref<HTMLDivElement>): ReactElement => (
    <div ref={ref} className={`wrapper ${className}`}>
        {children}
    </div>
));
