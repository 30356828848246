'use client';

import { useEffect, useState } from 'react';

const useScrollbarWidth = (): number => {
    const [scrollbarWidth, setScrollbarWidth] = useState<number>(0);

    useEffect((): void => {
        const outerElement = document.createElement('div');

        outerElement.style.visibility = 'hidden';
        outerElement.style.overflow = 'scroll';

        document.body.appendChild(outerElement);

        const innerElement = document.createElement('div');

        outerElement.appendChild(innerElement);

        setScrollbarWidth(outerElement.offsetWidth - innerElement.offsetWidth);

        if (outerElement.parentNode) {
            outerElement.parentNode.removeChild(outerElement);
        }
    }, []);

    return scrollbarWidth;
};

export default useScrollbarWidth;
