/* eslint-disable max-len */
import {
    forwardRef,
    ForwardRefExoticComponent,
    ReactElement,
    Ref,
} from 'react';

import { SvgIconProps } from '@/entities/Svg/Svg';

export const IconVisibilityOn: ForwardRefExoticComponent<SvgIconProps> = forwardRef(({
    className = '',
}, ref: Ref<SVGSVGElement>): ReactElement => (
    <svg ref={ref} viewBox="0 0 100 100" className={`icon ${className}`}>
        <ellipse className="icon__stroke" cx="50" cy="50" rx="16.6" ry="15.2" />
        <path className="icon__stroke" d="M91.2,50s-14.3-29.2-41.2-29.2S8.4,50,8.4,50c0,0,14.6,29.2,41.6,29.2s41.2-29.2,41.2-29.2Z" />
    </svg>
));
