'use client';

import { useCallback } from 'react';

import { useLocale } from '@/context/LocaleContext';
import { DateFormatOptions, DateIsoString, defaultDateFormatOptions } from '@/entities/Date/Date';
import { Language } from '@/entities/Locale/Locale';

type DateFormatFunction = (date?: DateIsoString, options?: DateFormatOptions) => string;

const dateLocales: Record<Language, string> = {
    [Language.nl]: 'nl-NL',
};

const useFormatDate = (): DateFormatFunction => {
    const { language } = useLocale();

    return useCallback<DateFormatFunction>((date, options = defaultDateFormatOptions) => {
        if (!date) return '';

        const dateLocale = dateLocales[language];
        const dateObject = new Date(date);

        return new Intl.DateTimeFormat(dateLocale, options).format(dateObject);
    }, [language]);
};

export default useFormatDate;
