import {
    forwardRef,
    ForwardRefExoticComponent,
    PropsWithChildren,
    ReactElement,
    Ref,
    RefAttributes,
} from 'react';

import classNames from 'classnames';
import Link, { LinkProps } from 'next/link';

import './LinkButton.scss';

export interface LinkButtonProps extends LinkProps, RefAttributes<HTMLAnchorElement> {
    hasAnimation?: boolean;
    isSmall?: boolean;
    text: string;
    hideLabel?: boolean;
    disabled?: boolean;
    labelClassName?: string;
    className?: string;
}

export const LinkButton: ForwardRefExoticComponent<PropsWithChildren<LinkButtonProps>> = forwardRef(({
    hasAnimation,
    isSmall,
    text,
    hideLabel,
    disabled,
    className = '',
    labelClassName = '',
    children,
    ...linkButtonProps
}, ref: Ref<HTMLAnchorElement>): ReactElement => {
    const linkButtonClassNames = classNames('link-button', {
        'link-button--is-disabled': disabled,
        'link-button--is-small': isSmall,
        'link-button--has-animation': hasAnimation,
    }, className);

    return (
        <Link
            {...linkButtonProps}
            ref={ref}
            title={hideLabel || children ? text : undefined}
            tabIndex={disabled ? -1 : 0}
            className={linkButtonClassNames}
        >
            {!hideLabel && (
                <span data-text={text} className={`link-button__label ${labelClassName}`}>
                    {hasAnimation ? text : children || text}
                </span>
            )}
        </Link>
    );
});
