export const clamp = (value: number, min: number, max: number): number => Math.min(Math.max(value, min), max);

export const convertNumberToCurrency = (number: number, locale = 'nl-NL'): string => number
    .toLocaleString(locale, {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    })
    .replace(',00', ',-');
