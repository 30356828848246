import { FC, PropsWithChildren, ReactElement } from 'react';

import './DialogContent.scss';

interface DialogContentProps {
    title?: string;
    className?: string;
}

export const DialogContent: FC<PropsWithChildren<DialogContentProps>> = ({
    title,
    className = '',
    children,
}): ReactElement => (
    <div className={`dialog-content ${className}`}>
        {title && (
            <header className="dialog-content__header">
                <h1 className="dialog-content__title">
                    {title}
                </h1>
            </header>
        )}

        <div className="dialog-content__content-wrapper">
            {children}
        </div>
    </div>
);
