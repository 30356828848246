/* eslint-disable max-len */
import {
    forwardRef,
    ForwardRefExoticComponent,
    ReactElement,
    Ref,
} from 'react';

import { SvgIconProps } from '@/entities/Svg/Svg';

export const IconCartWagon: ForwardRefExoticComponent<SvgIconProps> = forwardRef(({
    className = '',
    accentClassName = '',
}, ref: Ref<SVGSVGElement>): ReactElement => (
    <svg ref={ref} viewBox="0 0 100 100" className={`icon ${className}`}>
        <path className={accentClassName} d="M40.4,36h34.1v6.7h-25.8v5.4c.3-.3.6-.6.9-.8.3-.2.7-.5,1.2-.6.4-.2,1-.3,1.6-.4h2.2s10.9,0,10.9,0c1.7,0,3.2.2,4.5.5s2.4.8,3.3,1.5,1.6,1.6,2.1,2.8.7,2.6.7,4.3v2c0,2.4-.3,4.3-.9,5.6-.6,1.4-1.6,2.4-2.8,3.1s-2.8,1.1-4.7,1.3c-1.9.2-4.1.3-6.6.3h-7.5c-2.4,0-4.4,0-6.2-.3-1.8-.2-3.2-.5-4.4-1.1s-2-1.4-2.6-2.5-.9-2.6-.9-4.4v-1.8h8.8c0,.7,0,1.2.2,1.6,0,.4.4.8.7,1,.4.3.9.4,1.6.5.7,0,1.6.2,2.7.2h9c1.1,0,2,0,2.6-.2s1.1-.4,1.5-.8c.3-.3.5-.8.6-1.3,0-.5.2-1.2.2-1.9s0-1.6-.3-2.1c-.2-.5-.5-.9-.9-1.2s-1-.4-1.7-.5h-2.7s-10.1,0-10.1,0c-.7,0-1.4.2-1.9.5s-.8.9-.9,1.6h-8.3s0-18.8,0-18.8h-.2v-.2Z" />
        <polyline className="icon__stroke" points="2 8.7 7.6 8.7 7.6 74.6 98 74.6" />
        <polygon className="icon__stroke" points="22.1 28 93.9 28 93.9 60 85.9 74.6 30.8 74.6 22.1 60.1 22.1 28" />
        <circle className="icon__stroke" cx="26.3" cy="82.9" r="8.3" />
        <circle className="icon__stroke" cx="76.1" cy="82.9" r="8.3" />
    </svg>
));
