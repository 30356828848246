'use client';

import React from 'react';

import Link from 'next/link';

import { Logo } from '@/components/Logo/Logo';

import './MenuBar.scss';

interface MenuBarProps {
    className?: string;
}

export const MenuBar = ({
    className = '',
}: MenuBarProps) => (
    <div className={`menu-bar ${className}`}>
        <div className="menu-bar__wrapper">
            <Link href="/">
                <Logo className="menu-bar__logo" />
            </Link>
        </div>
    </div>
);
