/* eslint-disable max-len */
import {
    forwardRef,
    ForwardRefExoticComponent,
    ReactElement,
    Ref,
} from 'react';

import { SvgIconProps } from '@/entities/Svg/Svg';

export const IconFilter: ForwardRefExoticComponent<SvgIconProps> = forwardRef(({
    className = '',
}, ref: Ref<SVGSVGElement>): ReactElement => (
    <svg ref={ref} viewBox="0 0 100 100" className={`icon ${className}`}>
        <path d="M39,75.2v-16.8L6,19.2v-11.2h88v11.2l-33,39.2v33.6l-22-16.8Z" />
    </svg>
));
